<template>
  <div class="order">
    <div class="title">
      <div class="nav">
        <ul>
          <li v-for="list in navList" :key="list.status">
            <router-link :to="`/PersonalCenter/OrderManagement/${list.url}`">
              {{ list.content
              }}<span v-if="list.quantity">({{ list.quantity }})</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="search">
        <div class="same">
          <span>订单关键词</span>
          <input type="text" v-model="keyWord" @keyup.enter="SearchOder" />
        </div>
        <div class="same">
          <span>店铺名称</span>
          <input type="text" v-model="storeName" @keyup.enter="SearchOder" />
        </div>
        <div class="same">
          <span>下单时间</span>
          <el-date-picker
            v-model="value"
            type="daterange"
            range-separator="-"
            start-placeholder="选择开始日期"
            end-placeholder="选择结束日期"
            size="mini"
          >
          </el-date-picker>
        </div>
        <button @click="SearchOder">查&ensp;询</button>
      </div>
    </div>
    <div class="content">
      <div class="top">
        <span>货品</span>
        <span>数量</span>
        <span>单价（元）</span>
        <span>订单金额（元）</span>
        <span>订单状态</span>
        <span>交易操作</span>
      </div>
      <div v-loading="loading">
        <div
          class="goods-order"
          v-for="(list, index) in Order"
          :key="index"
          v-show="list.isShow"
        >
          <div class="order-title">
            <p class="order-num"><span>订单号：</span>{{ list.dealNumber }}</p>
            <p class="time">{{ list.tradeTime2 }}</p>
            <p class="store-name" @click="goStore(list)">
              <span><svg-icon icon-class="ic-shop" /></span>{{ list.storeName }}
            </p>
          </div>
          <div class="order-content">
            <div class="goods-box">
              <div
                class="goods"
                v-for="(item, i) in list.orderCommodityListItems"
                :key="i"
                v-show="item.isShow"
              >
                <img v-lazy="item.filePath" alt="" />
                <div class="details">
                  <div>
                    <span>品名：</span>
                    <h3 :title="item.commodityName" @click="goGoods(list, i)">
                      {{ item.commodityName }}
                    </h3>
                  </div>
                  <p>
                    品牌：<span>{{ item.brandName }}</span>
                  </p>
                  <p>
                    规格：<span>{{ item.specsParameter }}</span>
                  </p>
                </div>
                <p class="num">{{ item.quantity }}</p>
                <p class="price">{{ item.sellingPrice | price }}</p>
              </div>
              <div class="more" v-if="list.more">
                共{{ list.orderCommodityListItems.length }}种货品，还有{{
                  list.orderCommodityListItems.length - 2
                }}种，<span @click="more(index)">点击查看</span>
              </div>
            </div>
            <div class="total-price same">
              <h4>{{ list.totalAmount | price }}</h4>
              <span>不含运费</span>
            </div>
            <div class="state same">
              <p
                v-if="list.dealStatus === 0"
                style="color: var(--subjectColor)"
              >
                待付款
              </p>
              <p v-else-if="list.dealStatus === 2" style="color: #333333">
                待发货
              </p>
              <p v-else-if="list.dealStatus === 6" style="color: #333333">
                待收货
              </p>
              <p v-else-if="list.dealStatus === 7" style="color: #999999">
                交易关闭
              </p>
              <p v-else-if="list.dealStatus === 5" style="color: #999999">
                交易成功
              </p>
              <span @click="goOrderDetails(list.dealStatus, list.dealNumber)"
                >订单详情</span
              >
            </div>
            <div class="operation same">
              <button
                v-if="list.dealStatus === 0"
                class="btn1"
                @click="pay(list)"
              >
                立即支付
              </button>
              <button
                v-if="list.dealStatus === 0 || list.dealStatus === 1"
                class="btn4"
                @click="countermand(list)"
              >
                取消订单
              </button>
              <button v-else-if="list.dealStatus === 2" class="btn2">
                退款
              </button>
              <button
                v-if="list.dealStatus === 6 || list.dealStatus === 5"
                class="btn1"
              >
                查看物流
              </button>
              <button
                v-if="list.dealStatus === 6 || list.dealStatus === 5"
                class="btn3"
              >
                查看保单
              </button>
              <button v-if="list.dealStatus === 6" class="btn2">
                确认收货
              </button>
              <button
                v-else-if="list.dealStatus === 7"
                class="btn4"
                @click="deleteOrder(list)"
              >
                删除订单
              </button>
            </div>
          </div>
        </div>
        <el-empty
          v-if="Order.length === 0"
          description="暂时没有订单信息哦~"
          :image="img"
        ></el-empty>
      </div>

      <el-pagination
        :page-size="10"
        :pager-count="9"
        layout="prev, pager, next"
        :total="total"
        @prev-click="prev"
        @next-click="next"
        @current-change="current"
        v-if="Order.length != 0"
      ></el-pagination>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <p><svg-icon icon-class="ic_Tips" />店长请使用PDA终端开展店长业务。</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { mapState } from "vuex";
import { decimals, formatDate } from "@/utils/validate";

export default {
  name: "all-orders",
  // computed: {
  //   ...mapState("personalUser", ["userId"]),
  // },
  data() {
    return {
      value: "", //时间
      Order: "", //订单
      keyWord: "", //查询关键字
      storeName: "", //店铺名称
      pageNum: 1, //页码
      orderState: 0, //订单状态
      total: 0,
      loading: true,
      img: require("@/assets/images/EmptyOrder.png"),
      navList: [
        {
          content: "全部订单",
          url: "AllOrders",
          status: null,
        },
        {
          content: "待付款",
          url: "OrderPendingPayment",
          status: 0,
          quantity: 0,
        },
        {
          content: "待发货",
          url: "BacklogOrder",
          status: 2,
          quantity: 0,
        },
        {
          content: "待收货",
          url: "OrderToBeReceived",
          status: 6,
          quantity: 0,
        },
        {
          content: "已完成",
          url: "Finished",
          status: 5,
          quantity: 0,
        },
      ],
      dialogVisible: false,
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val);
        if (val.name === "order-pending-payment") {
          this.orderState = 0;
        } else if (val.name === "backlog-order") {
          this.orderState = 2;
        } else if (val.name === "order-to-be-received") {
          this.orderState = 6;
        } else if (val.name === "finished") {
          this.orderState = 5;
        } else if (val.name === "all-orders") {
          this.orderState = "";
        }
      },
      deep: true,
      immediate: true,
    },
    orderState: {
      handler: function (val, oldVal) {
        this.TransactionOrder();
      },
      deep: true,
      immediate: true,
    },
  },
  filters: {
    price(num) {
      return decimals(num);
    },
  },
  created() {
    // setTimeout(() => {
    // this.TransactionOrder();
    // }, 100);
  },
  beforeDestroy() {
    clearInterval(this.Time);
  },
  methods: {
    TransactionOrder() {
      let userId = sessionStorage.getItem("userId");
      this.loading = true;
      this.Time = setInterval(async () => {
        if (userId != "") {
          clearInterval(this.Time);
          const data = await this.$API.personalUser
            .ClosedOrderList({
              object: {
                dealStatus: this.orderState,
                keyWord: this.keyWord,
                userUuid: userId,
                storeName: this.storeName,
                tradeStartTime: this.value != null ? this.value[0] : "",
                tradeEndTime: this.value != null ? this.value[1] : "",
              },
              pageNum: this.pageNum,
              pageSize: 10,
            })
            .then((res) => {
              this.loading = false;
              return res;
            });
          let order = data.data.data;
          // console.log(order);
          this.total = data.data.total;
          for (let list of order) {
            list.more = false;
            list.isShow = true;
            list.tradeTime2 = formatDate(list.tradeTime);

            for (const item of list.orderCommodityListItems) {
              item.isShow = false;
              item.filePath = item.filePath.split(",")[0];
            }
            if (list.orderCommodityListItems.length < 2) {
              list.orderCommodityListItems[0].isShow = true;
            } else {
              list.orderCommodityListItems[0].isShow = true;
              list.orderCommodityListItems[1].isShow = true;
            }

            let EndTime = new Date(list.tradeTime); //截止时间
            let NowTime = new Date();

            if (
              list.dealStatus === 0 &&
              432000000 - (NowTime.getTime() - EndTime.getTime()) < 0
            ) {
              this.$API.personalUser.CancelOrder({
                object: {
                  dealNumber: list.dealNumber,
                },
              });
              // this.$API.personalUser.CancelOrder({
              //   object: {
              //     dealNumber: this.$route.query.dealNumber,
              //   },
              // });
            }
          }
          for (let i = 0; i < order.length; i++) {
            // console.log(order);
            if (order[i].orderCommodityListItems.length > 2) {
              order[i].more = true;
            }
          }

          this.Order = order;
          this.orderQuantity();
        }
      }, 500);
    },
    SearchOder() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.TransactionOrder();
      }, 300);
    },
    // async TransactionTotal() {
    //   const data = await this.$API.personalUser.ClosedOrderTotal({
    //     object: {
    //       keyWord: this.keyWord,
    //       userUuid: this.userId,
    //       storeName: this.storeName,
    //       tradeStartTime: this.value[0],
    //       tradeEndTime: this.value[1],
    //     },
    //     pageNum: this.pageNum,
    //     pageSize: 10,
    //   });
    //   this.total = data.data.data.sum;
    //   // console.log(data.data.data.sum);
    // },

    //查看更多
    more(i) {
      for (let iterator of this.Order[i].orderCommodityListItems) {
        iterator.isShow = true;
      }
      this.Order[i].more = false;
    },
    //立即支付
    pay(order) {
      this.dialogVisible = true;
      return;
      console.log(order);
      sessionStorage.setItem("totalAmount", order.totalAmount);
      sessionStorage.setItem("Time", order.tradeTime);
      sessionStorage.setItem("orderNum", order.dealNumber);

      this.$router.push({
        path: `/Pay/ModeOfPayment?orderNum=${order.dealNumber}`,
      });
    },
    //取消订单
    countermand(order) {
      // console.log(order.dealNumber);
      this.$confirm("您确定要取消当前订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$API.personalUser
            .CancelOrder({
              object: {
                dealNumber: order.dealNumber,
                flag: new Date().getTime(),
              },
            })
            .then((data) => {
              this.$message({
                message: "取消成功",
                type: "success",
                offset: "450",
                duration: 1000,
              });
              this.TransactionOrder();
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("取消失败");
            });
        })
        .catch(() => {});
    },
    //删除订单
    deleteOrder(order) {
      this.$confirm("您确定要删除当前订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$API.personalUser
            .DeleteOrder({
              object: {
                dealNumber: order.dealNumber,
              },
            })
            .then((data) => {
              this.$message({
                message: "删除成功",
                type: "success",
                offset: "450",
                duration: 1000,
              });
              this.TransactionOrder();
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    //订单数量
    async orderQuantity() {
      const data = await this.$API.personalUser.OrderStatusQuantity({
        object: {
          userUuid: sessionStorage.getItem("userId"),
        },
      });
      let orderQuantity = data.data.data;
      this.navList.forEach((element) => {
        for (let i = 0; i < orderQuantity.length; i++) {
          if (element.status === orderQuantity[i].dealStatus) {
            element.quantity = orderQuantity[i].quantity;
          }
        }
      });
    },
    //订单详情
    goOrderDetails(i, dealNumber) {
      // console.log(i,dealNumber);
      this.$message({
        message: "开发中",
        type: "warning",
      });
      // this.$router.push({
      //   name: `order-details`,
      //   query: { state: i, dealNumber: dealNumber },
      // });
    },
    //上一页
    prev() {
      this.pageNum--;
      // this.TransactionOrder();
    },
    //下一页
    next() {
      this.pageNum++;
      // this.TransactionOrder();
    },
    //分页数字
    current(i) {
      this.pageNum = i;
      this.TransactionOrder();
    },
    //跳转店铺
    goStore(list) {
      let a = this.$router.resolve({
        path: `/Store/HomePage/${list.storeId}`,
        query: { enterpriseId: list.senterpriseId },
      });
      window.open(a.href, "_blank");
    },
    //跳转商品
    goGoods(list, i) {
      let a = this.$router.resolve({
        path: `/Store/HomePage/${list.storeId}/GoodsDetails/${list.orderCommodityListItems[i].barcode}`,
        query: { enterpriseId: list.senterpriseId },
      });
      window.open(a.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  padding: 20px;
  background-color: #fff;
  .title {
    margin-bottom: 24px;
    .nav {
      background: #f8f8f8;
      border: 1px solid #dfdfdf;
      ul {
        display: flex;
        li {
          width: 128px;
          height: 44px;
          text-align: center;
          line-height: 44px;
          border-right: 1px solid #dfdfdf;
          font-size: 14px;
          a {
            transition: all 0.2s;
            color: #666666;
            width: 127px;
            height: 46px;
            display: block;
            border-top: transparent 2px solid;
            span {
              margin-left: 6px;
              color: var(--subjectColor);
            }
          }
          .router-link-active {
            color: var(--subjectColor);
            background-color: #fff;
            width: 127px;
            height: 46px;
            display: inline-block;
            line-height: 42px;
            border-top: var(--subjectColor) 2px solid;
            position: relative;
            top: -1px;
          }
        }
      }
    }
    .search {
      padding: 20px;
      border: 1px solid #dfdfdf;
      border-top: none;
      display: flex;

      .same {
        margin-right: 24px;
        span {
          font-size: 12px;
          line-height: 16px;
          color: #666666;
        }
        input {
          width: 166px;
          height: 32px;
          border: 1px solid #e8e8e8;
          outline: none;
          padding: 0 8px;
          font-size: 12px;
          color: #333;
          margin-left: 10px;
          transition: all 0.2s;
        }
        input:hover {
          border-color: #c0c4cc;
        }

        input:focus {
          border: 1px solid var(--subjectColor);
          outline: none;
        }
        ::v-deep .el-input__inner {
          margin-left: 10px;
          width: 274px;
          height: 32px;
          border-radius: 0;
        }
        .el-range-editor.is-active {
          border-color: var(--subjectColor);
        }
      }
      .same:nth-child(3) {
        margin-right: 20px;
      }
      button {
        width: 58px;
        height: 32px;
        border: none;
        outline: none;
        cursor: pointer;
        background: var(--subjectColor);
        border-radius: 2px;
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
  .content {
    .top {
      width: 960px;
      height: 44px;
      background: #f5f5f5;
      border: 1px solid #dddddd;
      line-height: 42px;
      font-size: 14px;
      color: #333333;
      span:nth-child(1) {
        margin: 0 325px 0 20px;
      }
      span:nth-child(3) {
        margin: 0 60px 0 80px;
      }
      span:nth-child(5) {
        margin: 0 64px 0 43px;
      }
    }
    .el-empty {
      padding: 100px 0;
    }
    .goods-order {
      border: 1px solid #dfdfdf;
      margin-top: 16px;
      .order-title {
        height: 40px;
        background: #f5f5f5;
        border-bottom: 1px solid #ebebeb;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333333;
        padding-left: 20px;
        p span {
          color: #999999;
          .svg-icon {
            margin-right: 4px;
          }
        }
        .time {
          margin: 0 40px;
        }
        .store-name:hover {
          color: var(--subjectColor);
          cursor: pointer;
          span {
            color: var(--subjectColor);
          }
        }
      }
      .order-content {
        display: flex;
        .goods-box {
          display: flex;
          flex-direction: column;
          .goods {
            width: 600px;
            padding: 20px;
            font-size: 14px;
            color: #333333;
            display: flex;
            border-bottom: 1px solid #dfdfdf;
            img {
              margin-right: 12px;
              width: 72px;
              height: 72px;
              border: 1px solid #efefef;
            }
            .details {
              width: 262px;
              padding-right: 10px;
              // cursor: default;
              div {
                display: flex;
                margin-bottom: 14px;

                h3 {
                  flex: 1;
                  height: 20px;
                  font-size: 14px;
                  font-weight: 400;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  cursor: pointer;
                }
                h3:hover {
                  color: var(--subjectColor);
                }
              }
              p {
                height: 16px;
                font-size: 12px;
                color: #999999;
                margin-top: 6px;
                span {
                  color: #666666;
                }
              }
            }
            .num {
              width: 40px;
              text-align: center;
            }
            .price {
              margin-left: 50px;
              width: 110px;
              text-align: center;
            }
          }
          .goods:last-child {
            border: none;
          }
          .more {
            text-align: center;
            font-size: 14px;
            color: #333333;
            padding: 12px 0;
            span {
              color: #2288ee;
              cursor: pointer;
            }
          }
        }
        .same {
          width: 120px;
          border-left: 1px solid #dfdfdf;
          text-align: center;
          padding: 20px 0;
          font-size: 14px;
          span {
            color: #666666;
          }
        }
        .total-price {
          h4 {
            font-weight: bold;
            line-height: 19px;
            color: #333333;
            margin-bottom: 8px;
          }
        }
        .state {
          font-size: 14px;
          p {
            margin-bottom: 8px;
          }
          span:hover {
            color: var(--subjectColor);
            cursor: pointer;
          }
        }
        .operation {
          button {
            width: 72px;
            height: 28px;
            border: none;
            outline: none;
            border-radius: 2px;
            font-size: 12px;
            margin-bottom: 12px;
            cursor: pointer;
          }
          button:last-child {
            margin: 0;
          }
          .btn1 {
            background: var(--subjectColor);
            color: #fff;
          }
          .btn1:hover {
            background: var(--btnColor);
          }
          .btn2 {
            background: #f5f5f5;
            border: 1px solid #dddddd;
            color: #333;
          }
          .btn2:hover {
            border-color: var(--subjectColor);
            color: var(--subjectColor);
            background: #fff;
          }
          .btn3 {
            background: #ffffff;
            border: 1px solid var(--subjectColor);
            color: var(--subjectColor);
          }
          .btn3:hover {
            background: var(--subjectColor);
            color: #fff;
          }
          .btn4 {
            background: #fff;
            border: 1px solid #dddddd;
            color: #999999;
          }
          .btn4:hover {
            border-color: var(--subjectColor);
            color: var(--subjectColor);
          }
        }
      }
    }

    .el-pagination {
      display: flex;
      justify-content: center;
      margin: 24px 0;
      ::v-deep button:hover {
        color: var(--subjectColor);
      }
      ::v-deep button {
        width: 34px;
        height: 34px;
        border: 1px solid #ddd;
        padding: 0;
        min-width: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 4px;
        i {
          margin-left: 1px;
        }
      }
      ::v-deep .el-pager {
        li {
          width: 34px;
          height: 34px;
          padding: 0;
          border: 1px solid #ddd;
          min-width: 0;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 32px;
          margin: 0 4px;
        }
        li.active {
          color: var(--subjectColor);
          border-color: var(--subjectColor);
        }
        .more {
          border: none;
          margin: 0;
          width: 26px;
        }
      }
    }
  }
  ::v-deep .el-dialog__wrapper {
    .el-dialog__header {
      padding: 12px 20px;
    }
    .el-dialog__body {
      padding-top: 0;

      .svg-icon {
        margin-right: 8px;
        font-size: 20px;
      }
    }
  }
}
</style>
<style lang="scss">
::v-deep .el-popper {
  ::v-deep .el-date-table td.available:hover {
    color: var(--subjectColor) !important;
  }
}
</style>